<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            {{ $t('Application.Title.Pods') }}
            <router-link to="/console/pods/create">
                <el-button class="float-right" type="primary">{{ $t('Application.Btn.NewApp') }}</el-button>
            </router-link>
            <a href="/pods#requirement" target="_blank" class="oxo-link float-right" style="margin-right: 20px;font-size: 12px">{{ $t('Application.Link.CheckRequirement') }}</a>
        </h2>
        <div class="oxo-list" v-loading="loading">
            <div class="oxo-table">
                <el-table
                        :data="tableData"
                        :empty-text="tableEmptyText"
                        :span-method="arraySpanMethod"
                        default-expand-all
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                    <el-table-column
                            :label="$t('Application.ListTable.TH1')"
                            width="190">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                            <p style="font-size: 12px;margin: 0;color: #aaa;">{{ scope.row.appCode }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH2')"
                            width="150">
                        <template slot-scope="scope">
                            <p style="font-size: 12px;margin: 0">{{ scope.row.version }}</p>
                            <span style="font-size: 12px;color: #aaa;">{{ scope.row.createTime }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH3')"
                            width="80">
                        <template slot-scope="scope">
                            {{ scope.row.status }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH4')"
                            width="170">
                        <template slot-scope="scope">
                            {{ scope.row.billingTypeName }}
                            <p style="font-size: 12px;margin: 0">{{ scope.row.expiredAt }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH5')"
                            width="150">
                        <template slot-scope="scope">
                            {{ scope.row.usage }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH6')"
                            width="130">
                        <template slot-scope="scope">
                            {{ scope.row.admin }}
                            <span v-if="!scope.row.admin ">
                                <el-button type="success" plain size="small" @click="preActiveApp(scope.row.appCode)">{{ $t('Application.ListTable.BtnActive') }}</el-button>
                            </span>
    <!--                        <p v-if="scope.row.admin" style="font-size: 12px;margin: 0">-->
    <!--                            <a href="javascript:" class="oxo-link primary">-->
    <!--                                {{ $t('Application.ListTable.BtnResetPassword') }}-->
    <!--                            </a>-->
    <!--                        </p>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Application.ListTable.TH7')">
                        <template slot-scope="scope">
                            <span v-if="scope.row.admin">
                                <a :href="scope.row.url" target="_blank" class="oxo-link primary">
                                    {{ $t('Application.ListTable.LinkToApp') }}
                                </a>
                                <el-divider direction="vertical"></el-divider>
                            </span>
                            <router-link v-if="scope.row.billingType > 0" v :to="'/console/app/renew/pods/'+scope.row.appCode" class="oxo-link primary">
                                {{ $t('Application.ListTable.LinkRenew') }}
                            </router-link>
                            <router-link v-else :to="'/console/app/renew/pods/'+scope.row.appCode" class="oxo-link warning">
                                {{ $t('Application.ListTable.LinkToOfficial') }}
                            </router-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="oxo-table-pagination">
                <el-pagination
                        @current-change="listPageCurrentChange"
                        background
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="$t('Application.Title.ActiveDialog', {code:form.appCode})" width="520px" :visible.sync="dialogFormVisible">
            <el-form label-position="left" :model="form">
                <el-form-item :label="$t('Application.FormCreate.Label4')" label-width="120px">
                    <el-input v-model="form.appName"
                              :placeholder="$t('Application.FormCreate.Label4Placeholder')" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Application.FormCreate.Label1')" label-width="120px">
                    <el-input v-model="form.admin"
                              :placeholder="$t('Application.FormCreate.Label1Placeholder')" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Application.FormCreate.Label2')" label-width="120px">
                    <el-input v-model="form.password"
                              :placeholder="$t('Application.FormCreate.Label2Placeholder')" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Application.FormCreate.Label3')" label-width="120px">
                    <el-input v-model="form.password_confirmation" type="password" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ $t('Action.Cancel') }}</el-button>
                <el-button type="primary" @click="activeApp">{{ $t('Application.FormCreate.BtnActive') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Pods",
        inject: ['reload'],
        data() {
            return {
                pageSize: 5,
                currentPage: 1,
                pageTotal: 0,
                loading: true,
                tableEmptyText: this.$t('Hint.Loading'),
                dialogFormVisible: false,
                tableData: [],
                form: {
                    appCode: '',
                    appName: '',
                    admin: '',
                    password: '',
                    password_confirmation: '',
                }
            }
        },
        methods: {
            loadList() {
                this.loading = true
                this.$axios.get('/pods/appList?pageSize='+this.pageSize+'&currentPage='+this.currentPage)
                    .then(res => {
                        this.pageTotal = res.total
                        this.tableData = []
                        if (res.list.length > 0) {
                            res.list.forEach((val) => {

                                let expiredAt = ''

                                if (val.expiredAt !== '-') {
                                    expiredAt = this.$t('Application.ListTable.TD1', { date: val.expiredAt })
                                }

                                this.tableData.push({
                                    name: val.name,
                                    appCode: val.appCode,
                                    billingType: val.billingType,
                                    billingTypeName: val.billingTypeName,
                                    expiredAt: expiredAt,
                                    status: val.status,
                                    usage: val.usage,
                                    admin: val.admin,
                                    version: val.version,
                                    url: val.url,
                                    createTime: val.createTime,
                                })
                            })
                        } else {
                            this.tableEmptyText = this.$t('Application.Hint.S1')
                        }
                        this.loading = false
                    }, err => {})
            },
            listPageCurrentChange(page) {
                this.currentPage = page
                this.loadList()
            },
            preActiveApp($code) {
                this.form.appCode = $code
                this.dialogFormVisible = true
            },
            activeApp () {
                this.$axios.post('/pods/active', {
                    fullscreenLoading: true,
                    ...this.form
                }).then(res => {
                    this.$message.success(this.$t('Hint.SubmitSuccess'))
                    this.reload()
                }, error => {})
            },
            arraySpanMethod({ row, column, rowIndex, columnIndex }) {
                // if (! row.children) {
                //     if (columnIndex === 2) {
                //         return [1, 4];
                //     }
                // }
            },
            tableRowClassName({row, rowIndex}) {
                if (row.children) {
                    return 'main-row';
                }
                return '';
            }
        },
        created() {
            this.loadList()
        }
    }
</script>

<style>
    .oxo-table .main-row {
        background: #f8f8f8;
    }
</style>