<template>
    <div class="oxo-console-page oxo-app-create">
        <h2 class="c-page-title">
            <el-page-header @back="goBack" :title="$t('Navigation.GoBack')" :content="$t('Application.Title.Renew')"></el-page-header>
        </h2>
        <div v-loading="loading">
            <el-card class="box-card">
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H3') }}</i>
                    <span>{{ appVersion }} {{ billingTypeName }}</span>
                </div>
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H6') }}</i>
                    <span>{{ productName }}</span>
                </div>
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H2') }}</i>
                    <span>{{ appName }}</span>
                </div>
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H1') }}</i>
                    <span>{{ form.appCode }}</span>
                </div>
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H4') }}</i>
                    <span>{{ expiredAt }}</span>
                </div>
                <div class="text item">
                    <i>{{ $t('Application.FormRenew.H5') }}</i>
                    <span>{{ packageTotal }}</span>
                </div>
            </el-card>
            <el-alert
                    style="margin-top: 24px"
                    v-if="billingType === 0"
                    :title="$t('Application.FormRenew.Alert')"
                    type="info"
                    center
                    show-icon
                    :closable="false">
            </el-alert>
            <div style="margin-top: 24px">
                <el-form ref="form" status-icon label-position="left" :model="form" label-width="100px">
                    <el-card class="box-card-renew" shadow="hover">
                        <div>
                            <span>
                                <el-checkbox v-model="buyPackage" :disabled="buyPackageDisabled">{{ $t('Application.FormRenew.T1') }}</el-checkbox>
                            </span>
                        </div>
                        <div v-show="buyPackage" class="box-card-body oxo-package">
                            <el-form-item :label="$t('Application.FormCreate.H7')">
                                <div class="oxo-radio-multiline">
                                    <el-radio v-for="item in packages" :key="item.id" v-model="selected.package" :label="item.id" border>
                                        <div class="title">
                                            <i>{{ item.name }}</i> {{ item.description }}
                                        </div>
                                        <span class="description">{{ item.price }}</span>
                                        <i class="el-icon el-icon-check"></i>
                                    </el-radio>
                                </div>
                            </el-form-item>
                        </div>
                    </el-card>
                    <el-card class="box-card-renew" style="margin-top: 24px" shadow="hover">
                        <div>
                            <span>
                                <el-checkbox v-model="subscription" :disabled="subscriptionDisabled">{{ $t('Application.FormRenew.T2') }}</el-checkbox>
                            </span>
                        </div>
                        <div v-show="subscription" class="box-card-body">
                            <el-form-item :label="$t('Application.FormCreate.H6')">
                                <el-select v-model="selected.duration">
                                    <el-option
                                            v-for="item in durationOption"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <span></span>
                            </el-form-item>
                        </div>
                    </el-card>
                    <el-card class="box-card" shadow="hover" style="margin-top: 24px">

                        <div style="margin-top: 4px" class="oxo-package">
                            <el-form-item :label="$t('Application.FormRenew.T3')">
                                <el-checkbox-group v-model="selected.service">
                                    <el-checkbox v-for="item in services" :key="item.id"
                                                 :disabled="billingType === 0 ? !item.optional : false"
                                                 :label="item.id">
                                        <el-popover
                                                placement="top-start"
                                                title=""
                                                :open-delay=400
                                                width=""
                                                trigger="hover">
                                            <span v-html="item.description"></span>
                                            <span slot="reference">
                                                <span style="color: #444">{{ item.name }} </span>
                                                <span style="color: #9a9a9a;font-size: 12px">{{ item.price }}</span>
                                            </span>
                                        </el-popover>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>
                    </el-card>
                    <div class="oxo-order-confirm-bar">
                        <el-button type="primary"
                                   :disabled=submitDisabled
                                   @click="submitForm('form')">{{ $t('Application.FormCreate.BtnSubmit') }}</el-button>
                        <table class="right">
                            <tr>
                                <td>
                                    <a href="/pods#price" target="_blank" class="oxo-link" style="float: right;margin-left: 20px;font-size: 12px">{{ $t('Application.FormCreate.LinkToPrice') }}</a>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <span class="text">{{ $t('Application.FormCreate.H4') }}: </span>
                                </td>
                                <td>
                                    <span class="price total">{{ total }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Renew",
        data () {
            return {
                loading: true,
                back: this.$route.params.back,
                renew: false,
                subscription: false,
                subscriptionDisabled: false,
                buyPackage: false,
                buyPackageDisabled: false,
                submitDisabled: true,
                productName: '',
                appName: '',
                appVersion: '',
                billingType: 1,
                billingTypeName: '',
                expiredAt: '',
                packageTotal: '',
                packages: [],
                services: [],
                total: '-',
                durationOption: [
                    {
                        value: 12,
                        label: '1 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 24,
                        label: '2 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 36,
                        label: '3 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 48,
                        label: '4 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 60,
                        label: '5 ' + this.$t('Application.FormCreate.C3')
                    }
                ],
                selected: {
                    duration: 12,
                    package: 2,
                    service: [],
                },
                form: {
                    appCode: this.$route.params.appCode,
                    duration: 0,
                    package: 0,
                    service: [],
                }
            }
        },
        methods: {
            goBack() {
                this.$router.push({path: '/console/' + this.back})
            },
            getAppInfo() {
                this.$axios.get('/app/renew/info/'+this.form.appCode)
                    .then(res => {

                        this.appName = res.app_name
                        this.productName = res.os_product_name
                        this.appVersion = res.os_app_name
                        this.expiredAt = res.expired_at
                        this.packageTotal = res.package_total
                        this.billingType = res.billing_type
                        this.billingTypeName = res.billing_type_name
                        this.packages = res.packages
                        this.services = res.services

                        if (this.billingType === 0) { // 试用版
                            this.selected.service.push(res.services[0].id)
                            this.subscription = true
                            this.subscriptionDisabled = true
                            this.buyPackage = true
                            this.buyPackageDisabled = true
                            this.submitDisabled = false
                        }

                        this.loading = false

                    }, error => {})
            },
            optionChange() {
                this.form.duration = this.subscription ? this.selected.duration : 0
                this.form.package = this.buyPackage ? this.selected.package : 0
                this.form.service = this.selected.service
                this.submitDisabled = !(this.form.duration > 0 || this.form.package > 0 || this.form.service.length > 0)
            },
            getTotal() {
                this.total = this.$t('Application.FormCreate.C5')
                this.$axios.post('/app/renew/getTotal', {
                    ...this.form,
                }).then(res => {
                    this.total = res.total
                }, err => {
                    this.total = this.$t('Application.Hint.S4')
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/app/renew/confirm', {
                            fullscreenLoading: true,
                            ...this.form,
                        }).then(res => {
                            this.$message.success(this.$t('Hint.SubmitSuccess'))
                            this.$router.push({ path: '/console/order/detail/' + res.orderNo })
                        }, err => {})
                    } else {

                        return false;
                    }
                });
            },
        },
        watch: {
            subscription() {
                this.optionChange()
            },
            buyPackage() {
                this.optionChange()
            },
            selected: {
                handler: function () {
                    this.optionChange()
                },
                deep: true
            },
            form: {
                handler: function () {
                    this.getTotal()
                },
                deep: true
            },
        },
        created() {
            this.getAppInfo()
        }
    }
</script>

<style lang="scss" scoped>
    .el-form-item {
        margin-bottom: 0;
    }
    .item {
        padding: 6px 0;
        width: 50%;
        display: inline-block;
        i {
            display: inline-block;
            width: 100px;
            font-style: normal;
        }
    }
    .box-card-body {
        margin-top: 24px;
    }
    .box-card-renew {
        border-color: #DCDFE6;
    }
</style>