<template>
    <div class="oxo-console-page oxo-app-create">
        <h2 class="c-page-title">
            <el-page-header @back="goBack" :title="$t('Navigation.GoBack')" :content="$t('Application.Title.CreatePods')"></el-page-header>
        </h2>
        <div v-loading="loading">
            <el-form ref="form" status-icon label-position="left" :model="form" label-width="100px">
                <el-form-item :label="$t('Application.FormCreate.H1')">
                    <el-radio-group  v-model="form.appCode" >
                        <el-radio-button v-for="item in optional.app" :disabled="item.disabled" :key="item.code" :label="item.code">
                            {{ item.name }}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <div class="oxo-package">
                    <div v-show="form.appCode === 'PODS_PRO_TRIALS'">
                        <el-form-item :label="$t('Application.FormCreate.H5')">
                            <span>1 {{ $t('Application.FormCreate.C2') }}</span>
                        </el-form-item>
                    </div>
                    <div v-show="form.appCode === 'PODS_PRO'">
                        <el-form-item :label="$t('Application.FormCreate.H7')">
                            <div class="oxo-radio-multiline">
                                <el-radio v-for="item in optional.package.PODS_PRO"
                                          :key="item.id" v-model="selected.package.PODS_PRO" :label="item.id"
                                          border>
                                    <div class="title">
                                        <i>{{ item.name }}</i> {{ item.description }}
                                    </div>
                                    <span class="description">{{ item.price }}</span>
                                    <i class="el-icon el-icon-check"></i>
                                </el-radio>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('Application.FormCreate.H6')">
                            <el-select v-model="selected.subscription.PODS_PRO">
                                <el-option
                                        v-for="item in subscriptionOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <span></span>
                        </el-form-item>
                        <el-form-item :label="$t('Application.FormCreate.H8')">
                            <el-checkbox-group v-model="selected.service.PODS_PRO">
                                <el-checkbox v-for="item in optional.service.PODS_PRO" :key="item.id"
                                             :disabled="!item.optional"
                                             :label="item.id">
                                    <el-popover
                                            placement="top-start"
                                            title=""
                                            :open-delay=400
                                            width=""
                                            trigger="hover">
                                        <span v-html="item.description"></span>
                                        <span slot="reference">
                                            <span style="color: #444">{{ item.name }} </span>
                                            <span style="color: #9a9a9a;font-size: 12px">{{ item.price }}</span>
                                        </span>
                                    </el-popover>
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                    <div v-show="form.appCode === 'PODS_GO'">
                        <el-form-item :label="$t('Application.FormCreate.H7')">
                            <div class="oxo-radio-multiline">
                                <el-radio v-for="item in optional.package.PODS_GO" :key="item.id" v-model="selected.package.PODS_GO" :label="item.id" border>
                                    <div class="title">
                                        <i>{{ item.name }}</i> {{ item.description }}
                                    </div>
                                    <span class="description">{{ item.price }}</span>
                                    <i class="el-icon el-icon-check"></i>
                                </el-radio>
                            </div>
                        </el-form-item>
                        <el-form-item :label="$t('Application.FormCreate.H6')">
                            <el-select v-model="selected.subscription.PODS_GO">
                                <el-option
                                        v-for="item in subscriptionOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('Application.FormCreate.H8')">
                            <el-checkbox-group v-model="selected.service.PODS_GO">
                                <el-checkbox v-for="item in optional.service.PODS_GO" :key="item.id"
                                             :disabled="!item.optional"
                                             :label="item.id">
                                    <el-popover
                                            placement="top-start"
                                            title=""
                                            :open-delay=400
                                            width=""
                                            trigger="hover">
                                        <span v-html="item.description"></span>
                                        <span slot="reference">
                                            <span style="color: #444">{{ item.name }} </span>
                                            <span style="color: #9a9a9a;font-size: 12px">{{ item.price }}</span>
                                        </span>
                                    </el-popover>
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                </div>
                <div class="oxo-order-confirm-bar">
                    <el-button type="primary" @click="submitForm('form')">{{ $t('Application.FormCreate.BtnSubmit') }}</el-button>
                    <table class="right">
                        <tr>
                            <td>
                                <a href="/pods#price" target="_blank" class="oxo-link" style="float: right;margin-left: 20px;font-size: 12px">{{ $t('Application.FormCreate.LinkToPrice') }}</a>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td>
                                <span class="text">{{ $t('Application.FormCreate.H4') }}: </span>
                            </td>
                            <td>
                                <span class="price total">{{ total }}</span>
                            </td>
                            <td>

                            </td>
                        </tr>
                    </table>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PodsCreate",
        data () {
            return {
                loading: true,
                optional: {
                    app: [],
                    package: {},
                    service: {},
                },
                total: '-',
                selected: {
                    subscription: {
                        PODS_PRO: 12,
                        PODS_GO: 12,
                        PODS_PRO_TRIALS: 1,
                    },
                    package: {
                        PODS_PRO: 2,
                        PODS_GO: 2,
                        PODS_PRO_TRIALS: 0,
                    },
                    service: {
                        PODS_PRO: [],
                        PODS_GO: [],
                        PODS_PRO_TRIALS: [],
                    },
                },
                form: {
                    productCode: 'PODS',
                    appCode: 'PODS_PRO',
                    duration: 12,
                    package: 2,
                    service: [],
                },
                subscriptionOptions: [
                    {
                        value: 12,
                        label: '1 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 24,
                        label: '2 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 36,
                        label: '3 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 48,
                        label: '4 ' + this.$t('Application.FormCreate.C3')
                    },
                    {
                        value: 60,
                        label: '5 ' + this.$t('Application.FormCreate.C3')
                    }
                ]
            }
        },
        methods: {
            goBack() {
                this.$router.push({ path: '/console/pods' })
            },
            optionChange() {
                this.form.duration = this.selected.subscription[this.form.appCode]
                this.form.package = this.selected.package[this.form.appCode]
                this.form.service = this.selected.service[this.form.appCode]
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.post('/app/create', {
                            fullscreenLoading: true,
                            ...this.form,
                        }).then(res => {
                            this.$message.success(this.$t('Hint.SubmitSuccess'))
                            this.$router.push({ path: '/console/order/detail/' + res.orderNo })
                        }, err => {})
                    } else {
                        return false;
                    }
                });
            },
            getTotal() {
                this.total = this.$t('Application.FormCreate.C5')
                this.$axios.post('/app/new/getTotal', {
                    ...this.form,
                }).then(res => {
                    this.total = res.total
                }, err => {
                    this.total = this.$t('Application.Hint.S4')
                })
            },
        },
        watch: {
            selected: {
                handler: function () {
                    this.optionChange()
                },
                deep: true
            },
            'form.appCode'() {
                this.optionChange()
            },
            form: {
                handler: function () {
                    this.getTotal()
                },
                deep: true
            },
        },
        created() {
            this.$axios.get('/app/new/info/'+this.form.productCode, {})
                .then(res => {
                    this.optional.app = res.app
                    this.optional.package = res.package
                    this.optional.service = res.service
                    this.selected.service.PODS_PRO.push(res.service.PODS_PRO[0].id)
                    this.selected.service.PODS_GO.push(res.service.PODS_GO[0].id)
                    this.loading = false
                }, err => {})
        }
    }
</script>

<style lang="scss" scoped>
    .el-input {
        width: 370px;
    }
    .el-divider {
        margin: 36px 0;
    }
</style>